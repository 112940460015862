import './App.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import Collapsible from './components/objects/collapsible'
import axios from 'axios';

import { Button, Col } from 'react-bootstrap'
import { FiMap, FiMapPin, FiPhone } from "react-icons/fi"
import { Link } from "react-router-dom";

function BookAppointment() {
    const [Sections, setSection] = useState([]);
    const [place, setPlace] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');


    const handleClickScroll = (reference) => {

        const element = document.getElementById(reference);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClick = (ref, evnt) => {
        evnt.preventDefault();
        const element = document.getElementById('where');
        switch (ref) {
            case 'alabang':
                setPlace('Alabang Town Center');
                setAddress('Service Town Alabang Town Center, Brgy. Ayala Alabang, Muntinlupa City');
                setContact('(02) 804-3361');
                if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    element.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case 'ayala':
                setPlace('Ayala Malls 30th');
                setAddress('30 Meralco Avenue, Pasig City');
                setContact('(02) 79157665');
                if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    element.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case 'smaura':
                setPlace('SM Aura');
                setAddress('Basement 1, SM Aura, McKinley Pkwy, Taguig City');
                setContact('(02) 79157678');
                if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    element.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case 'up':
                setPlace('UP Town Center');
                setAddress('Basement 1, SM Aura, McKinley Pkwy, Taguig City');
                setContact('(02) 79157678');
                if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    element.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            default:
                break;
        }
    };

    // const Sections = [{
    //   SectionCode: "home",
    //   ClassName: "",
    //   DynamicStyle: { backgroundImage: 'url(/images/maxwax_40.png)', backgroundSize: "cover" },
    //   Panels: [{
    //     PanelCode: "NavHome",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'NavHomeP1',
    //       ClassName: "",
    //       DynamicStyle: {},
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'img',
    //         ObjectSrc: '/images/MXLogo.png', //For Image Only
    //         ClassName: "",
    //         ObjectDynamicStyle: {
    //           height: "250px", marginLeft: "30px"
    //         }
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: "sideNav",
    //         ObjectSrc: "",
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {
    //           marginTop: "8%", marginLeft: "12%", marginBottom: "0px", marginRight: "12%"
    //         },
    //         Children: [{
    //           Value: "HOME",
    //           ClassName: "hover-underline-animation",
    //           Reference: "home",
    //           DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
    //         }
    //           , {
    //           Value: "ABOUT US",
    //           ClassName: "hover-underline-animation",
    //           Reference: "aboutus",
    //           DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
    //         }
    //           , {
    //           Value: "SERVICES",
    //           ClassName: "hover-underline-animation",
    //           Reference: "waxingservices",
    //           DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
    //         }
    //           , {
    //           Value: "IN STORE PRODUCTS",
    //           ClassName: "hover-underline-animation",
    //           Reference: "shop",
    //           DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
    //         }
    //           , {
    //           Value: "WHERE TO FIND US",
    //           ClassName: "hover-underline-animation",
    //           Reference: "wheretofindus",
    //           DynamicStyle: { display: "block", color: "white", fontSize: "20px", textDecoration: "none" }
    //         }
    //         ]
    //       }
    //         , {
    //         ObjectId: "3",
    //         ObjectType: "button",
    //         ObjectSrc: "",
    //         ClassName: "",
    //         ObjectText: "BOOK AN APPOINMENT",
    //         ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "#cf7b3a", display: "block", color: "white", fontSize: "20px", marginTop: "30px", marginLeft: "12%", borderWidth: "2px", width: "50%" }
    //       }
    //       ]
    //     }]
    //   }
    //     , {
    //     PanelCode: "SecondHome",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "skip3Container",
    //       DynamicStyle: { backgroundColor: "#cf7b3a", paddingTop: "30px" }, //style="background-color:#cf7b3a; padding: 0px; padding-top: 30px;"
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Smooth skin",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "60px"
    //         }
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "made easy",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "60px"
    //         }
    //       },
    //       {
    //         ObjectId: "3",
    //         ObjectType: "button",
    //         ObjectSrc: "",
    //         ClassName: "centerTest",
    //         ObjectText: "SHOP NOW",
    //         ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "white", display: "block", color: "white", fontSize: "20px", borderWidth: "2px", width: "50%" }
    //       }
    //       ]
    //     }]
    //   }
    //   ]
    // },
    // {
    //   SectionCode: "aboutus",
    //   ClassName: "",
    //   DynamicStyle: { backgroundImage: 'url(/images/maxwax_13.png)', backgroundSize: "cover" },
    //   Panels: [{
    //     PanelCode: "FirstAboutUs",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: {},
    //       Objects: [{}]
    //     }]
    //   },
    //   {
    //     PanelCode: "SecondAboutUs",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: { backgroundColor: "#cf7b3a", height: "70%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" },
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Why Maxwax?",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "60px"
    //         }
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "At Maxwax Studio, we love EVERYBODY. Love yourself while looking and feeling",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "15px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "3",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "#SexyInYourOwnSkin",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "15px"
    //         }
    //       },
    //       {
    //         ObjectId: "4",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Wheter you're petite or tall, size XL or small - Every BODY is invited to join the fun!",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "15px"
    //         }
    //       }
    //         , {
    //         ObjectId: "5",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Maxwax studio envisions a totally new concept in grooming and style-one where our clients can expect the highest level of expertise and hygiene standards, in a place where they can feel comfortable in any shape, size, skin tone and texture they have.",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "15px", margin: "0px"
    //         }
    //       }
    //         , {
    //         ObjectId: "6",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "We max out the confidence in you!",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "15px"
    //         }
    //       }
    //         , {
    //         ObjectId: "6",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Our team of aestheticians is dedicated to deliver the best in terms of waxing service and style. We provide waxing and brow grooming services that is fit for any size, lifestyle or any way you want. Most of all, we provide results that are completely on-point, thanks to our inherent understanding of how today's trens can work for your personal style and way of life.",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "15px"

    //         }
    //       },
    //       {
    //         ObjectId: "7",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Remember: some beauty regimens are best left to experts",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "15px"

    //         }
    //       }
    //         , {
    //         ObjectId: "8",
    //         ObjectType: "button",
    //         ObjectSrc: "",
    //         ClassName: "centerTest",
    //         ObjectText: "THE MAXWAX BRAZILIAN EXPERIENCE",
    //         ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "white", display: "block", color: "white", fontSize: "20px", borderWidth: "2px", width: "50%" }
    //       }
    //       ]
    //     },
    //     {
    //       SubPanelCode: 'Panel2',
    //       ClassName: "",
    //       DynamicStyle: { backgroundColor: "black", height: "30%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" },
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Your road to Smooth skin is as easy as 1, 2, 3!",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "50px"
    //         }
    //       }
    //       ]
    //     }]
    //   }
    //   ]
    // },
    // {
    //   SectionCode: "steps",
    //   ClassName: "",
    //   DynamicStyle: { backgroundImage: 'url("/images/white-fabric.png")', backgroundSize: "cover" },
    //   Panels: [{
    //     PanelCode: "FirstSteps",
    //     ClassName: "col-md-4",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: { height: "100%", padding: "20px" },
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "3",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "4",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Step 1:",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "#cf7b3a", fontSize: "60px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "5",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "6",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Every BODY is welcome to join the fun",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "#cf7b3a", fontSize: "30px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "7",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "8",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Choose your desired area for waxing",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "#cf7b3a", fontSize: "20px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "9",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "10",
    //         ObjectType: "button",
    //         ObjectSrc: "",
    //         ClassName: "centerTest",
    //         ObjectText: "WAXING SERVICES",
    //         ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "#cf7b3a", display: "block", color: "#cf7b3a", fontSize: "20px", borderWidth: "2px", width: "50%" }
    //       }

    //       ]
    //     }]
    //   },
    //   {
    //     PanelCode: "SecondSteps",
    //     ClassName: "col-md-4",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: { height: "100%", padding: "20px" },
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "3",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "4",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Step 2:",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "#cf7b3a", fontSize: "60px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "5",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "6",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Great things come in packages",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "#cf7b3a", fontSize: "30px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "7",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "8",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Choose a package to achieve that smooth and sexy skin",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "#cf7b3a", fontSize: "20px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "9",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "10",
    //         ObjectType: "button",
    //         ObjectSrc: "",
    //         ClassName: "centerTest",
    //         ObjectText: "PACKAGES",
    //         ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "#cf7b3a", display: "block", color: "#cf7b3a", fontSize: "20px", borderWidth: "2px", width: "50%" }
    //       }]
    //     }]
    //   }
    //     , {
    //     PanelCode: "ThirdSteps",
    //     ClassName: "col-md-4",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: { height: "100%", padding: "20px" },
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "3",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "4",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Step 3:",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "#cf7b3a", fontSize: "60px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "5",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "6",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Your aftercare at home",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "#cf7b3a", fontSize: "30px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "7",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "8",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Go home with hair free skin and an after care product to maintain that smooth glow",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "#cf7b3a", fontSize: "20px", margin: "0px"
    //         }
    //       },
    //       {
    //         ObjectId: "9",
    //         ObjectType: 'br',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {}
    //       },
    //       {
    //         ObjectId: "10",
    //         ObjectType: "button",
    //         ObjectSrc: "",
    //         ClassName: "centerTest",
    //         ObjectText: "Products",
    //         ObjectDynamicStyle: { backgroundColor: "transparent", borderColor: "#cf7b3a", display: "block", color: "#cf7b3a", fontSize: "20px", borderWidth: "2px", width: "50%" }
    //       }]
    //     }]
    //   }
    //   ]
    // },
    // {
    //   SectionCode: "waxingservices",
    //   ClassName: "",
    //   DynamicStyle: { backgroundImage: 'url("/images/maxwax_02.png")', backgroundSize: "cover" },
    //   Panels: [{
    //     PanelCode: "FirstWaxService",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: {},
    //       Objects: [{}]
    //     }]
    //   }
    //     , {
    //     PanelCode: "SecondWaxService",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: { backgroundColor: "#ffecec", height: "100%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" },
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Waxing Services",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "black", fontSize: "60px"
    //         }
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: 'accordion',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {},
    //         Children: [{
    //           Title: "Brazilian",
    //           Body: [
    //             {
    //               Text: "Intimate Bikini Wax",
    //               Price: "900.00"
    //             },
    //             {
    //               Text: "Full Brazilian Wax",
    //               Price: "1000.00"
    //             }
    //           ],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Brow",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Body",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Face",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Men's",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Laser and Brows",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         }
    //         ]
    //       }
    //       ]
    //     }]
    //   }
    //   ]
    // },
    // {
    //   SectionCode: "packages",
    //   ClassName: "",
    //   DynamicStyle: { backgroundImage: 'url("/images/maxwax_23.png")', backgroundSize: "cover" },
    //   Panels: [{
    //     PanelCode: "FirstPackages",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: {},
    //       Objects: [{}]
    //     }]
    //   },
    //   {
    //     PanelCode: "SecondPackages",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: { backgroundColor: "#ffecec", height: "100%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" },
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Packages",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "black", fontSize: "60px"
    //         }
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: 'accordion',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {},
    //         Children: [{
    //           Title: "Good Looking",
    //           Body: [{
    //             Text: "Underarm + Lash Perming + Threading",

    //             Price: "1000"
    //           }],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Essentials",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Bare Minimum",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "All Dolled Up",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Flawless like Filter",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Big Night",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         }
    //           ,
    //         {
    //           Title: "Manscaped",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Smooth Bod",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "black", fontSize: "15px", textDecoration: "none" }
    //         }
    //         ]
    //       }
    //       ]
    //     }]
    //   }
    //   ]
    // },
    // {
    //   SectionCode: "shop",
    //   ClassName: "",
    //   DynamicStyle: { backgroundImage: 'url("/images/maxwax_07.png")', backgroundSize: "cover" },
    //   Panels: [{
    //     PanelCode: "FirstShop",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: {},
    //       Objects: [{}]
    //     }]
    //   },
    //   {
    //     PanelCode: "SecondShop",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: { backgroundColor: "#8ca19e", height: "100%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" },
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Shop by Skin Concern",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "60px"
    //         }
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: 'accordionimage',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {},
    //         Children: [{
    //           Title: "Uneven Tan",
    //           Body: [{
    //             Product: "Gigi No Bump",
    //             ProductDesc: "Topical Solution",
    //             ProductPrice: "P 475.00",
    //             Desc: "Get an instant radiance with our exfoliating products. Our non-abrasive formulas even skin tone, unclog pores, remove dead skin cells, and help you get the most out of your skin care routine.",
    //             ObjectSrc: "/images/davines.png",
    //           },],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Dark Spots/areas",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Ingrown Hair",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Dry Skin",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Uneven Skin Tone",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Thin Brows",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         }
    //         ]
    //       }
    //       ]
    //     }]
    //   }
    //   ]
    // },
    // {
    //   SectionCode: "wheretofindus",
    //   ClassName: "",
    //   DynamicStyle: { backgroundImage: 'url("/images/maxwax_00.png")', backgroundSize: "cover" },
    //   Panels: [{
    //     PanelCode: "FirstShop",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: {},
    //       Objects: [{}]
    //     }]
    //   },
    //   {
    //     PanelCode: "SecondShop",
    //     ClassName: "col-md-6",
    //     DynamicStyle: { padding: "0px" },
    //     SubPanels: [{
    //       SubPanelCode: 'Panel1',
    //       ClassName: "",
    //       DynamicStyle: { backgroundColor: "#8ca19e", height: "100%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" },
    //       Objects: [{
    //         ObjectId: "1",
    //         ObjectType: 'text',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "Where to Find us?",
    //         ObjectDynamicStyle: {
    //           textAlign: "center", color: "white", fontSize: "60px"
    //         }
    //       },
    //       {
    //         ObjectId: "2",
    //         ObjectType: 'accordionplace',
    //         ObjectSrc: '', //For Image Only
    //         ClassName: "",
    //         ObjectText: "",
    //         ObjectDynamicStyle: {},
    //         Children: [{
    //           Title: "Alabang Town Center",
    //           Body: [{
    //             Address: "Service Town Alabang Town Center, Brgy. Ayala Alabang, Muntinlupa City",
    //             Contact: "(02) 804-3361",
    //           }],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Ayala Malls 30th",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "SM Aura",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "Shangri La Manila",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         },
    //         {
    //           Title: "UP Town Center",
    //           Body: [],
    //           ClassName: "",
    //           DynamicStyle: { color: "white", fontSize: "15px", textDecoration: "none" }
    //         }
    //         ]
    //       }
    //       ]
    //     }]
    //   }
    //   ]
    // }
    // ]

    return (
        <>
            <Collapsible></Collapsible>
            <div className='content'>
                <section id="home" style={{ backgroundImage: "url(/images/maxwax_40.png)", backgroundSize: "cover" }}>
                    <div className='row' style={{ height: "100%" }}>
                        <div id="NavHome" className='col-md-6' style={{ padding: "0px" }}>
                            <div id='NavHomeP1'>
                                <div>
                                    <img src='/images/MXLogo.png' style={{ height: "250px", marginLeft: "30px" }}></img>
                                </div>
                                <div style={{ marginTop: "8%", marginLeft: "12%", marginBottom: "0px", marginRight: "12%" }}>
                                    <Link to="/" style={{ textDecoration: "none" }}>
                                        <a className='hover-underline-animation' style={{ "display": "block", "color": "white", "fontSize": "20px", "textDecoration": "none" }} onClick={() => handleClickScroll('')}>HOME</a>
                                    </Link>
                                    <Link to="/" style={{ textDecoration: "none" }}>
                                        <a className='hover-underline-animation' style={{ "display": "block", "color": "white", "fontSize": "20px", "textDecoration": "none" }} onClick={() => handleClickScroll('')}>ABOUT US</a>
                                    </Link>
                                    <Link to="/" style={{ textDecoration: "none" }}>
                                        <a className='hover-underline-animation' style={{ "display": "block", "color": "white", "fontSize": "20px", "textDecoration": "none" }} onClick={() => handleClickScroll('')}>SERVICES</a>
                                    </Link>
                                    <Link to="/" style={{ textDecoration: "none" }}>
                                        <a className='hover-underline-animation' style={{ "display": "block", "color": "white", "fontSize": "20px", "textDecoration": "none" }} onClick={() => handleClickScroll('')}>WHERE TO FIND US</a>
                                    </Link>
                                    <Link to="/" style={{ textDecoration: "none" }}>
                                        <a className='hover-underline-animation' style={{ "display": "block", "color": "white", "fontSize": "20px", "textDecoration": "none" }} onClick={() => handleClickScroll('')}>IN STORE PRODUCTS</a>
                                    </Link>
                                </div>
                            </div>
                            <Button className='' style={{ backgroundColor: "transparent", borderColor: "#cf7b3a", display: "block", color: "white", fontSize: "20px", marginTop: "30px", marginLeft: "12%", borderWidth: "2px", width: "50%" }}>BOOK AN APPOINTMENT</Button>

                        </div>
                        <div id="SecondHome" className='col-md-6' style={{ padding: "0px" }}>
                            <div id='SecondHomeP1' style={{ backgroundColor: "#cf7b3a", height: "100%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" }}>
                                <p style={{ textAlign: "center", color: "white", fontSize: "60px" }}>Select your branch</p><br />
                                <div className='row'>
                                    <div className='col-md-6' style={{ paddingLeft: "5%" }}><Button onClick={(evnt) => (handleClick('alabang', evnt))} className='' style={{ backgroundColor: "transparent", borderColor: "white", display: "block", color: "white", fontSize: "20px", borderWidth: "2px", width: "100%" }}>ALABANG TOWN CENTER</Button></div>
                                    <div className='col-md-6' style={{ paddingRight: "5%" }}><Button onClick={(evnt) => (handleClick('ayala', evnt))} className='' style={{ backgroundColor: "transparent", borderColor: "white", display: "block", color: "white", fontSize: "20px", borderWidth: "2px", width: "100%" }}>AYALA MALLS 30TH</Button></div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-md-6' style={{ paddingLeft: "5%" }}><Button onClick={(evnt) => (handleClick('smaura', evnt))} className='' style={{ backgroundColor: "transparent", borderColor: "white", display: "block", color: "white", fontSize: "20px", borderWidth: "2px", width: "100%" }}>SM AURA</Button></div>
                                    <div className='col-md-6' style={{ paddingRight: "5%" }}><Button onClick={(evnt) => (handleClick('up', evnt))} className='' style={{ backgroundColor: "transparent", borderColor: "white", display: "block", color: "white", fontSize: "20px", borderWidth: "2px", width: "100%" }}>UP TOWN CENTER</Button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="where" style={{ backgroundImage: "url(/images/maxwax_40.png)", backgroundSize: "cover" }}>
                    <div className='row' style={{ height: "100%" }}>
                        <div id="NavHome" className='col-md-6' style={{ padding: "0px" }}>
                        </div>
                        <div id="SecondHome" className='col-md-6' style={{ padding: "0px" }}>
                            <div id='SecondHomeP1' style={{ backgroundColor: "#8ca19e", height: "100%", paddingTop: "30px", paddingLeft: "100px", paddingRight: "100px" }}>
                                <p style={{ textAlign: "center", color: "white", fontSize: "60px" }}>Let's Wax at </p><br />
                                <p style={{ color: "white", fontSize: "30px", marginBottom: "0px" }}>{place} </p><br />
                                <p style={{ color: "white" }}><FiMapPin style={{ fontSize: "25px", color: "white" }} /> {address}</p>
                                <p style={{ color: "white" }}><FiPhone style={{ fontSize: "25px", color: "white" }} /> {contact}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default BookAppointment;
