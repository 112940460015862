import React from 'react'
import Image from './objects/image'
import SideNav from './objects/sidenav'
import { Button, Col } from 'react-bootstrap'
import Collapsible from './objects/collapsible'
import DualLink from './objects/duallink'
import AccordionImage from './objects/accordionimage'
import AccordionPlace from './objects/accordionplace'
import { Link } from "react-router-dom";


const handleClickScroll = (reference, evnt) => {
    evnt.preventDefault();
    const element = document.getElementById(reference);
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
    }
};


class SubPanel extends React.Component {

    
    render() {
        return (
            <div id={this.props.SubPanelCode} className={this.props.ClassName} style={this.props.DynamicStyle}>
                {/* {this.props.Objects.map(o => (
                    
                        <div></div>
                ))} */}

                {this.props.Objects.map((o) => {
                    //need to change to switch di ko alam bakit ako nag if dito eh
                    if (o.ObjectType === "img") {
                        return <Image
                            ObjectSrc={o.ObjectSrc}
                            ClassName={o.ClassName}
                            ObjectDynamicStyle={o.ObjectDynamicStyle}
                        />
                    }
                    if (o.ObjectType === "sideNav") {
                        return <SideNav
                            ObjectSrc={o.ObjectSrc}
                            ClassName={o.ClassName}
                            ObjectDynamicStyle={o.ObjectDynamicStyle}
                            Children={o.Children}
                        />
                    }
                    if (o.ObjectType === "button") {
                        return <Button onClick={(evnt) => (handleClickScroll(o.Reference, evnt))} className={o.ClassName} style={o.ObjectDynamicStyle}>{o.ObjectText}</Button>
                    }
                    if (o.ObjectType === "text") {
                        return <p style={o.ObjectDynamicStyle} className={o.ClassName}>{o.ObjectText}</p>
                    }
                    if (o.ObjectType === "br") {
                        return <br />
                    }
                    if (o.ObjectType === "accordion") {
                        return <Collapsible Children={o.Children}></Collapsible>
                    }
                    if (o.ObjectType === "duallink") {
                        return <DualLink></DualLink>
                    }
                    if (o.ObjectType === "accordionimage") {
                        return <AccordionImage Children={o.Children} />
                    }
                    if (o.ObjectType === "accordionplace") {
                        return <AccordionPlace Children={o.Children} />
                    }
                    if (o.ObjectType === "buttonlink") {
                        return (
                            <Link to={o.Reference} style={{ textDecoration: "none" }}>
                                <Button className={o.ClassName} style={o.ObjectDynamicStyle}>{o.ObjectText}</Button>
                            </Link>
                        )
                    }
                    return <div></div>
                })}

                {/* <Image></Image> */}
                {/* <CollapsibleList></CollapsibleList> */}

            </div>
        )
    }
}

export default SubPanel