import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import React, { useState, useEffect } from 'react';



// function Collapsible({ Children }) {
//     const pChildren = Children;
//     return (
//         <div>
//             <Accordion>
//                 {console.log(pChildren)}
//                 {Children?.map((c) => (
//                     <AccordionItem>
//                         <AccordionHeader className="accButton" >
//                             <span>
//                                 {c.Title}
//                             </span>
//                         </AccordionHeader>
//                         <AccordionBody>
//                             <div className="accordionBody">
//                             </div>
//                         </AccordionBody>
//                     </AccordionItem>
//                 ))}
//             </Accordion>
//         </div>

//     );
// }

const Collapsible = ({ Children }) => {
    return (
        <div>
            <Accordion>
                {console.log('dito')}
                {console.log(Children)}
                {Children?.map((c) => (
                   
                    <AccordionItem>
                        <AccordionHeader className="accButton"  >
                            <span style={c.DynamicStyle}>
                                {c.Title}
                            </span>
                        </AccordionHeader>
                        <AccordionBody>
                            <div className="accordionBody" style={c.DynamicStyle}>
                                {c.Body.map((b) => (
                                    <>
                                        <p><span style={{float:"left"}}>{b.Text}</span><span style={{float: "right"}}>{b.Price}</span></p>
                                        <br></br>
                                    </>
                                ))}
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>

    );
};

export default Collapsible;