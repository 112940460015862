import React from 'react'
import Panel from './panel'
class Section extends React.Component {
    render() {
        return (
            <section id={this.props.SectionCode} className={this.props.ClassName} style={this.props.DynamicStyle}>
                <div className='row' style={{ height: "100%"}}>
                    {this.props.Panels?.map(p => (
                        <Panel
                            PanelCode={p.PanelCode}
                            ClassName={p.ClassName}
                            DynamicStyle={p.DynamicStyle}
                            SubPanels={p.SubPanels}
                        />
                    ))}
                </div>
            </section>
        )
    }
}

export default Section