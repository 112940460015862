import React from 'react'
import SubPanel from './subpanel'

class Panel extends React.Component {
    render() {
        return (
            <div id={this.props.PanelCode} className={this.props.ClassName} style={this.props.DynamicStyle}>
                {this.props.SubPanels.map(p => (
                    <SubPanel
                        SubPanelCode={p.PanelCode}
                        ClassName={p.ClassName}
                        DynamicStyle={p.DynamicStyle}
                        Objects={p.Objects}
                    />
                ))}

               
            </div>
        )
    }
}

export default Panel