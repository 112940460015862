import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import React, { useState, useEffect } from 'react';
import { FiMap, FiMapPin, FiPhone } from "react-icons/fi"

const AccordionPlace = ({ Children }) => {
    { console.log(Children) }
    return (
        <div>
            <Accordion>
                {Children?.map((c) => (
                    <AccordionItem>
                        <AccordionHeader className="accButton"  >
                            <span style={c.DynamicStyle}>
                                {c.Title}

                            </span>
                        </AccordionHeader>
                        <AccordionBody>
                            <div className="accordionBody" style={c.DynamicStyle}>
                                {c.Body.map((b) => (
                                    <>
                                        <p><FiMapPin style={{fontSize: "25px"}} /> {b.Address}</p>
                                        <p><FiPhone style={{fontSize: "25px"}} /> {b.Contact}</p>
                                    </>
                                ))}
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>

    );
};

export default AccordionPlace;
