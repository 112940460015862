import React from 'react'

class Image extends React.Component {
    render() {
        return (
            <div>
                 <img src={this.props.ObjectSrc} style={this.props.ObjectDynamicStyle}></img>
            </div>
        )
    }
}

export default Image