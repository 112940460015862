import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import React, { useState, useEffect } from 'react';


const AccordionImage = ({ Children }) => {
    { console.log(Children) }
    return (
        <div>
            <Accordion>
                {Children?.map((c) => (
                    <AccordionItem>
                        <AccordionHeader className="accButton"  >
                            <span style={c.DynamicStyle}>
                                {c.Title}

                            </span>
                        </AccordionHeader>
                        <AccordionBody>
                            {console.log(c)}
                            <div className="accordionBody" style={c.DynamicStyle}>
                                {c.Body.map((b) => (
                                    <>
                                        <div style={{textAlign: "center"}}>
                                            <img src={b.ObjectSrc}></img>
                                            <p style={{fontWeight: "bold"}}>{b.Product}</p>
                                            <p>{b.ProductDesc}</p>
                                            <p>{b.ProductPrice}</p>
                                        </div>

                                        <p>{b.Desc}</p>
                                    </>
                                ))}
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>

    );
};

export default AccordionImage;
