const DualLink = () => {
    return (
        <>
            <div className="row">
                <div className="offset-4 col-md-3">
                    test
                </div>
                <div className="col-md-3">
                    test
                </div>
            </div>
        </>



    );
};

export default DualLink;