import React from 'react'

const SideNav = ({ ClassName, Children, ObjectDynamicStyle }) => {

    const handleClickScroll = (reference) => {
        console.log(reference)
        const element = document.getElementById(reference);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <div className={ClassName} style={ObjectDynamicStyle}>
            {Children?.map(c => (
                <a className={c.ClassName} style={c.DynamicStyle} onClick={() => handleClickScroll(c.Reference)}>
                    {console.log(c.Reference)}
                    {c.Value}</a>
            ))}
        </div>
    )
}

// class SideNav extends React.Component {

//     render() {
//         return (
//             <div className={this.props.ClassName} style={this.props.ObjectDynamicStyle}>
//                 {this.props.Children.map(c => (
//                     <a className={c.ClassName} style={c.DynamicStyle}>{c.Value}</a>
//                 ))}
//             </div>
//         )
//     }
// }

export default SideNav